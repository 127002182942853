import {ExtraOptions, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {MatchPublicPageGuard} from "./_guards/public-page.guard";
import {MatchPrivatePageGuard} from "./_guards/private-page.guard";
import {MatchPublicPageWorksheetGeneratorsGuard} from "./_guards/public-page-worksheet-generators.guard";
import {MatchPublicPageWorksheetGeneratorsItemsGuard} from "./_guards/public-page-worksheet-generators-items.guard";
import {MatchPublicPageAuthActionsGuard} from "./_guards/public-page-auth-actions.guard";
import {MatchPublicPageWorkbookSeriesGuard} from "./_guards/public-page-workbooks-series.guard";
import {MatchPublicPageBlogCategoriesGuard} from "./_guards/public-page-blog-categories.guard";
import {MatchPublicPageBlogPostGuard} from "./_guards/public-page-blog-post.guard";
import {MatchInsetGradeGuard} from "./_guards/isset-grade.guard";
import {MatchUnsetGradeGuard} from "./_guards/unset-grade.guard";
import {MatchPublicPageGradeGuard} from "./_guards/public-page-grade.guard";
import {MatchPublicPageSerieGuard} from "./_guards/public-page-serie.guard";


const routerOptions: ExtraOptions = {
  initialNavigation: 'enabledBlocking',
  enableTracing: false,
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
  paramsInheritanceStrategy: 'always',
  onSameUrlNavigation: 'reload',
  scrollOffset: [0, 30]
};

const routWithComingSoon = [
  {
    path: '',
    children: [
      {
        path: 'terms',
        loadChildren: () => import('./_ui/pages/public/bh-page-terms/bh-page-terms.module').then((mod) => mod.BhPageTermsModule),
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./_ui/pages/public/bh-page-privacy-policy/bh-page-privacy-policy.module').then((mod) => mod.BhPagePrivacyPolicyModule),
      },
      {
        path: 'auth',
        children: [
          {
            path: ':action/:token',
            canMatch: [MatchPublicPageGuard, MatchPublicPageAuthActionsGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-auth/bh-page-auth.module').then((mod) => mod.BhPageAuthModule),
            data: {
              noindex: true,
            }
          },
          {
            path: ':action',
            canMatch: [MatchPublicPageGuard, MatchPublicPageAuthActionsGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-auth/bh-page-auth.module').then((mod) => mod.BhPageAuthModule),
            data: {
              noindex: true,
            }
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-auth/bh-page-auth.module').then((mod) => mod.BhPageAuthModule),
            data: {
              noindex: true,
            }
          },
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-not-found/bh-page-not-found.module').then((mod) => mod.BhPageNotFoundModule),
            data: {
              noindex: true
            }
          },
        ]
      },
      {
        path: 'book-video-explanation',
        children: [
          {
            path: ':workbook_slug',
            loadChildren: () => import('./_ui/pages/public/bh-page-bve-single/bh-page-bve-single.module').then((mod) => mod.BhPageBveSingleModule),
          },
          {
            path: '',
            loadChildren: () => import('./_ui/pages/public/bh-page-bve/bh-page-bve.module').then((mod) => mod.BhPageBveModule),
          },
        ]
      },
      {
        path: 'store',
        children: [
          {
            path: 'cart',
            loadChildren: () => import('./_ui/pages/public/bh-page-store-cart/bh-page-store-cart.module').then((mod) => mod.BhPageStoreCartModule),
          },
          {
            path: 'checkout',
            loadChildren: () => import('./_ui/pages/public/bh-page-store-checkout/bh-page-store-checkout.module').then((mod) => mod.BhPageStoreCheckoutModule),
          },
          {
            path: 'product/:workbook_slug',
            loadChildren: () => import('./_ui/pages/public/bh-page-workbook/bh-page-workbook.module').then((mod) => mod.BhPageWorkbookModule),
          },
          {
            path: 'product',
            redirectTo: '/store/listing',
          },
          {
            path: 'listing/:filter_grade',
            canMatch: [MatchPublicPageGradeGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-store-listing/bh-page-store-listing.module').then((mod) => mod.BhPageStoreListingModule),
          },
          {
            path: 'listing/:filter',
            redirectTo: '/store/listing',
          },
          {
            path: ':serie',
            canMatch: [MatchPublicPageSerieGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-store-series/bh-page-store-series.module').then((mod) => mod.BhPageStoreSeriesModule),
          },
          {
            path: 'listing',
            loadChildren: () => import('./_ui/pages/public/bh-page-store-listing/bh-page-store-listing.module').then((mod) => mod.BhPageStoreListingModule),
          },
          {
            path: '',
            loadChildren: () => import('./_ui/pages/public/bh-page-store/bh-page-store.module').then((mod) => mod.BhPageStoreModule),
          },
        ]
      },
      {
        path: 'worksheet/:worksheet_slug',
        loadChildren: () => import('./_ui/pages/public/bh-page-worksheet/bh-page-worksheet.module').then((mod) => mod.BhPageWorksheetModule),
      },
      {
        path: 'worksheet',
        redirectTo: '/worksheets',
      },
      {
        path: 'worksheets',
        children: [
          {
            path: ':filter_grade',
            canMatch: [MatchPublicPageGradeGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-worksheets/bh-page-worksheets.module').then((mod) => mod.BhPageWorksheetsModule),
          },
          {
            path: '',
            loadChildren: () => import('./_ui/pages/public/bh-page-worksheets/bh-page-worksheets.module').then((mod) => mod.BhPageWorksheetsModule),
          },
        ]
      },
      {
        path: 'blog',
        children: [
          {
            path: 'page/:page_num',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
          },
          {
            path: ':category/page/:page_num',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
            canMatch:[MatchPublicPageBlogCategoriesGuard]
          },
          {
            path: ':category',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
            canMatch:[MatchPublicPageBlogCategoriesGuard]
          },
          {
            path: ':post',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog-post/bh-page-blog-post.module').then((mod) => mod.BhPageBlogPostModule),
            canMatch:[MatchPublicPageBlogPostGuard],
          },
          {
            path: '',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
          },
          {
            path: '**',
            redirectTo: '/blog'
          }
        ]
      },
      {
        path: 'account',
        children: [
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            children:[
              {
                path: 'logout',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-logout/bh-page-account-logout.module').then((mod) => mod.BhPageAccountLogoutModule),
              },
              {
                path: 'subscriptions',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-subscriptions/bh-page-account-subscriptions.module').then((mod) => mod.BhPageAccountSubscriptionsModule),
              },
              {
                path: 'purchases',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-purchases/bh-page-account-purchases.module').then((mod) => mod.BhPageAccountPurchasesModule),
              },
              {
                path: 'workbooks',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-workbooks/bh-page-account-workbooks.module').then((mod) => mod.BhPageAccountWorkbooksModule),
              },
              {
                path: 'downloads',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-downloads/bh-page-account-downloads.module').then((mod) => mod.BhPageAccountDownloadsModule),
              },
              {
                path: 'awards',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-awards/bh-page-account-awards.module').then((mod) => mod.BhPageAccountAwardsModule),
              },
              {
                path: 'children',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-children/bh-page-account-children.module').then((mod) => mod.BhPageAccountChildrenModule),
              },
              {
                path: 'change',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-grades/bh-page-grades.module').then((mod) => mod.BhPageGradesModule),
              },
              {
                path: '',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account/bh-page-account.module').then((mod) => mod.BhPageAccountModule),
              },
            ]
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            children:[
              {
                path: '',
                loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
                data: {
                  noindex: true,
                }
              },
              {
                path: '**',
                loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
                data: {
                  noindex: true,
                }
              }
            ]
          },
        ]
      },
      {
        path: 'practices',
        children: [
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            children:[
              {
                path: 'practice_drills/:quiz_id',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-practices-quiz/bh-page-practices-quiz.module').then((mod) => mod.BhPagePracticesQuizModule),
              },
              {
                path: 'practice_drills',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-practices-drills/bh-page-practices-drills.module').then((mod) => mod.BhPagePracticesDrillsModule),
              },
              {
                path: 'practice_questions/:quiz_id',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-practices-quiz/bh-page-practices-quiz.module').then((mod) => mod.BhPagePracticesQuizModule),
              },
              {
                path: 'practice_questions',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-practices-questions/bh-page-practices-questions.module').then((mod) => mod.BhPagePracticesQuestionsModule),
              },
              {
                path: 'video_lectures/:lectures_id',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-practices-video/bh-page-practices-video.module').then((mod) => mod.BhPagePracticesVideoModule),
              },
              {
                path: 'video_lectures',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-practices-video-lectures/bh-page-practices-video-lectures.module').then((mod) => mod.BhPagePracticesVideoLecturesModule),
              },
              {
                path: 'worksheets/:worksheet_id',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-practices-worksheet/bh-page-practices-worksheet.module').then((mod) => mod.BhPagePracticesWorksheetModule),
              },
              {
                path: 'worksheets',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-practices-worksheets/bh-page-practices-worksheets.module').then((mod) => mod.BhPagePracticesWorksheetsModule),
              },
              {
                path: '',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-practices/bh-page-practices.module').then((mod) => mod.BhPagePracticesModule),
              },
            ]
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            children:[
              {
                path: '',
                loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
                data: {
                  noindex: true,
                }
              },
              {
                path: '**',
                loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
                data: {
                  noindex: true,
                }
              }
            ]
          },
        ]
      },
      {
        path: 'dashboard',
        canMatch: [MatchPrivatePageGuard],
        children: [
          {
            path: '',
            canMatch: [MatchInsetGradeGuard],
            loadChildren: () => import('./_ui/pages/private/bh-page-dashboard/bh-page-dashboard.module').then((mod) => mod.BhPageDashboardModule),
          },
          {
            path: '',
            canMatch: [MatchUnsetGradeGuard],
            loadChildren: () => import('./_ui/pages/private/bh-page-grades/bh-page-grades.module').then((mod) => mod.BhPageGradesModule),
          }
        ]
      },
      {
        path: 'dashboard',
        canMatch: [MatchPublicPageGuard],
        redirectTo: 'auth',
      },


      {
        path: 'k12',
        loadChildren: () => import('./_ui/pages/public/bh-page-landing-k12/bh-page-landing-k12.module').then((mod) => mod.BhPageLandingK12Module),
      },
      {
        path: 'shsat',
        loadChildren: () => import('./_ui/pages/public/bh-page-landing-shsat/bh-page-landing-shsat.module').then((mod) => mod.BhPageLandingShsatModule),
      },
      {
        path: '',
        children: [
          {
            path: '',
            //canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
          },
        ]
      },
      {
        path: '**',
        loadChildren: () => import('./_ui/pages/public/bh-page-not-found/bh-page-not-found.module').then((mod) => mod.BhPageNotFoundModule),
        data: {
          not_found: true,
        }
      }





/*      {
        path: 'worksheet-generators',
        children: [
          {
            path: ':category/:type',
            loadChildren: () => import('./_ui/pages/public/bh-page-worksheet-generator/bh-page-worksheet-generator.module').then((mod) => mod.BhPageWorksheetGeneratorModule),
            canMatch: [MatchPublicPageWorksheetGeneratorsItemsGuard]
          },
          {
            path: ':category',
            loadChildren: () => import('./_ui/pages/public/bh-page-worksheet-generators-category/bh-page-worksheet-generators-category.module').then((mod) => mod.BhPageWorksheetGeneratorsCategoryModule),
            canMatch:[MatchPublicPageWorksheetGeneratorsGuard]
          },
          {
            path: '',
            loadChildren: () => import('./_ui/pages/public/bh-page-worksheet-generators/bh-page-worksheet-generators.module').then((mod) => mod.BhPageWorksheetGeneratorsModule),
          },
        ]
      },
      {
        path: 'workbooks',
        children: [
          {
            path: ':category/:workbook',
            loadChildren: () => import('./_ui/pages/public/bh-page-workbook/bh-page-workbook.module').then((mod) => mod.BhPageWorkbookModule)
          },
          {
            path: ':category',
            loadChildren: () => import('./_ui/pages/public/bh-page-workbooks-series/bh-page-workbooks-series.module').then((mod) => mod.BhPageWorkbooksSeriesModule),
            canMatch:[MatchPublicPageWorkbookSeriesGuard]
          },
          {
            path: '',
            loadChildren: () => import('./_ui/pages/public/bh-page-workbooks/bh-page-workbooks.module').then((mod) => mod.BhPageWorkbooksModule),
          },
        ]
      },
      {
        path: 'invite/:uin',
        canMatch: [MatchPublicPageGuard],
        loadChildren: () => import('./_ui/pages/public/bh-page-invite/bh-page-invite.module').then((mod) => mod.BhPageInviteModule),
        data: {
          noindex: true,
        }
      },*/
/*      {
        path: 'contacts',
        loadChildren: () => import('./_ui/pages/public/bh-page-contacts/bh-page-contacts.module').then((mod) => mod.BhPageContactsModule),
      },*/
/*      {
        path: 'blog',
        children: [
          {
            path: 'page/:page_num',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
          },
          {
            path: ':category/page/:page_num',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
            canMatch:[MatchPublicPageBlogCategoriesGuard]
          },
          {
            path: ':category',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
            canMatch:[MatchPublicPageBlogCategoriesGuard]
          },
          {
            path: ':post',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog-post/bh-page-blog-post.module').then((mod) => mod.BhPageBlogPostModule),
            canMatch:[MatchPublicPageBlogPostGuard],
          },
          {
            path: '',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
          },
          {
            path: '**',
            redirectTo: '/blog'
          }
        ]
      },*/
/*      {
        path: 'rating',
        children: [
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            loadChildren: () => import('./_ui/pages/private/bh-page-rating/bh-page-rating.module').then((mod) => mod.BhPageRatingModule),
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
            data: {
              noindex: true,
              private: true
            }
          },
        ]
      },
      {
        path: 'support',
        children: [
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            loadChildren: () => import('./_ui/pages/private/bh-page-support/bh-page-support.module').then((mod) => mod.BhPageSupportModule),
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
            data: {
              noindex: true,
              private: true
            }
          },
        ]
      },
      {
        path: 'game',
        children: [
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            loadChildren: () => import('./_ui/pages/private/bh-page-bonus-game/bh-page-bonus-game.module').then((mod) => mod.BhPageBonusGameModule),
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
            data: {
              noindex: true,
              private: true
            }
          },
        ]
      },

      {
        path: 'task/:task_id',
        children: [
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            loadChildren: () => import('./_ui/pages/private/bh-page-task/bh-page-task.module').then((mod) => mod.BhPageTaskModule),
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
            data: {
              noindex: true,
              private: true
            }
          },
        ]
      },
      {
        path: 'search',
        loadChildren: () => import('./_ui/pages/public/bh-page-search/bh-page-search.module').then((mod) => mod.BhPageSearchModule),
      },
      {
        path: 'ua',
        children: [
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
          },
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
            data: {
              noindex: true,
              private: true
            }
          },
          {
            path: '**',
            redirectTo: '/'
          }
        ]
      },*/
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot([], routerOptions),
    RouterModule.forChild(routWithComingSoon),
  ],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule {
}
