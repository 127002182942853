import {Action, NgxsOnChanges, NgxsOnInit, NgxsSimpleChange, Selector, State, StateContext, Store} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {SettingsModel} from "../_models/settings.model";
import {
  ChangeLanguageAction,
  ChangeMaxAction,
  ChangeSoundAction,
  DisallowComingSoonAction,
  SetSoundAction
} from "../_actions/settings.actions";
import {TranslocoService} from "@ngneat/transloco";
import {AuthAllowAccess} from "../../auth/_actions/auth.actions";
import {LocaleService} from "../../../_services/locale.service";


export const _SettingsDefault: SettingsModel = {
  coming_soon: false,
  first_join: true,
  max: {
    energy: 10,
    life: 3,
  },
  languages: 'en',
  sound: true
};

@State<SettingsModel>({
  name: 'APO_SETTINGS',
  defaults: _SettingsDefault,
})
@Injectable()
export class SettingsState implements NgxsOnInit, NgxsOnChanges {


  constructor(private store: Store, private transloco: TranslocoService, private localeService: LocaleService) {}

  ngxsOnInit(ctx?: StateContext<any>): any {
    const state = ctx.getState();

    if(state.languages != _SettingsDefault.languages) {
      this.transloco.setActiveLang(state.languages);
    }
    this.localeService.initLocale(state.languages);

  }
  ngxsOnChanges(change: NgxsSimpleChange<SettingsModel>): void {}


  @Selector()
  static selectSettings( state: SettingsModel ) {
    return state;
  }

  @Selector()
  static selectComingSoon( state: SettingsModel ) {
    return state.coming_soon;
  }




  @Action(ChangeLanguageAction)
  public changeLanguageAction(ctx: StateContext<SettingsModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      languages:  payload.language,
    });
    this.localeService.setLocale(payload.language);
  }

  @Action(ChangeSoundAction)
  public changeSoundAction(ctx: StateContext<SettingsModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      sound: !state.sound
    });
  }
  @Action(SetSoundAction)
  public setSoundAction(ctx: StateContext<SettingsModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      sound:  payload.sound,
    });
  }



  @Action(DisallowComingSoonAction)
  public disallowComingSoonAction(ctx: StateContext<SettingsModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      coming_soon:false
    });
  }

  @Action(AuthAllowAccess)
  authAllowAccess(ctx: StateContext<SettingsModel>) {
    const state = ctx.getState();
    if(state.first_join) {
      ctx.patchState({
        ...state,
        first_join:false
      });
    }
  }


  @Action(ChangeMaxAction)
  public changeMaxAction(ctx: StateContext<SettingsModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      max: payload.max
    });
  }

}
