export const environment = {
  baseUrl: 'https://argoprep.online',
  api_endpoint: '',
  production: true,
  storeDevtools: false,
  hmr: false,
  apiUrl: '',
  default_language: 'en'
};

