import {
  Action,
  createSelector,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {CategoryModel, PracticesModel} from "../_models/practice.model";
import {AuthAllowAccess} from "../../auth/_actions/auth.actions";
import {PracticesService} from "../_services/practice.service";
import {LoadPracticesCategory, LoadPracticesQuiz, LoadPracticesVideo} from "../_actions/practice.actions";
import {UserState} from "../../user/_state/user.state";
import {RouterSelectors} from "../../router/router.selectors";
import {RouterNavigation, RouterState} from "@ngxs/router-plugin";
import {CrumbsModel} from "../../crumbs/_models/crumbs.model";
import {WorkbooksState} from "../../workbooks/_state/workbooks.state";
import {WorkbookModel} from "../../workbooks/_models/workbooks.model";
import {TranslocoService} from "@ngneat/transloco";
import {UpdateTitle} from "../../crumbs/_actions/crumbs.actions";



export const _PracticesDefault: PracticesModel = {
  current: [],
  videos: [],
  quiz: []
};

@State<PracticesModel>({
  name: 'APO_PRACTICE',
  defaults: _PracticesDefault,
})
@Injectable()
export class PracticesState implements NgxsOnInit, NgxsOnChanges {

  constructor(private store: Store, private practicesService: PracticesService, private transloco: TranslocoService) {}

  ngxsOnInit(ctx?: StateContext<any>): any {}
  ngxsOnChanges(change: NgxsSimpleChange<PracticesModel>): void {}

  @Selector()
  static selectPractice( state: PracticesModel ) {
    return state;
  }
  @Selector()
  static selectCurrentPractice( state: PracticesModel ) {
    return state.current;
  }

  static selectCurrentPracticeBySlug( _slug: string ) {
    return createSelector([ PracticesState.selectCurrentPractice ], ( _practice: CategoryModel[]) => {
      return _practice.find(x => x.slug === _slug);
    });
  }

  static selectPracticeById(_id: string) {
    return createSelector(
        [PracticesState.selectCurrentPractice],
        (_practice: CategoryModel[]) => {
          function findCategoryById(
              categories: CategoryModel[],
              id: string
          ): CategoryModel | null {
            for (const category of categories) {
              if (category._id === id) {
                return category;
              }
              if (category.children && category.children.length > 0) {
                const found = findCategoryById(category.children, id);
                if (found) {
                  return found;
                }
              }
            }
            return null;
          }

          return findCategoryById(_practice, _id);
        }
    );
  }

  @Selector()
  static selectVideoLectures( state: PracticesModel ) {
    return state.videos;
  }

  @Selector()
  static selectQuiz( state: PracticesModel ) {
    return state.quiz;
  }

  @Action(AuthAllowAccess)
  authAllowAccess(ctx: StateContext<PracticesModel>) {
    ctx.dispatch(new LoadPracticesCategory());
  }

  @Action(RouterNavigation)
  async routerNavigation(ctx: StateContext<PracticesModel>, payload) {
    if(payload.routerState.params.lectures_id) {
      this.store.dispatch(new LoadPracticesVideo());
    }
    if(payload.routerState.params.quiz_id) {
      this.store.dispatch(new LoadPracticesQuiz());
    }
  }



  @Action(LoadPracticesCategory)
  async loadPracticesCategory(ctx: StateContext<PracticesModel>) {
    const _state_user = this.store.selectSnapshot(UserState);
    let _practices_current_state = ctx.getState();
    let _practices_by_grade = await this.practicesService.getGradePractice(_state_user.settings.grade._id);
    if(_practices_by_grade) {
      if(JSON.stringify(_practices_current_state.current) !== JSON.stringify(_practices_by_grade)) {
        ctx.patchState({
          ..._practices_current_state,
          current: _practices_by_grade
        });
      }
    }
  }

  @Action(LoadPracticesVideo)
  async loadPracticesVideo(ctx: StateContext<PracticesModel>) {
    let _state_current = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    const _state_router = this.store.selectSnapshot(RouterState);
    let _videos = await this.practicesService.getPracticeVideos(_state_router.state.params.lectures_id);
    if(_videos.length > 0) {
      ctx.patchState({
        ..._state_current,
        videos: _videos
      });
    }
  }

  @Action(LoadPracticesQuiz)
  async loadPracticesQuiz(ctx: StateContext<PracticesModel>) {
    let _state_current = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    const _state_router = this.store.selectSnapshot(RouterState);
    let _quiz = await this.practicesService.getPracticeQuiz(_state_router.state.params.quiz_id);
    if(_quiz.length > 0) {
      ctx.patchState({
        ..._state_current,
        quiz: _quiz
      });
    }
  }

}
