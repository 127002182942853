import {Action, NgxsOnChanges, NgxsOnInit, NgxsSimpleChange, Selector, State, StateContext, Store} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {ModalModel} from "../_models/modal.model";
import {ModalCloseAction, ModalOpenAction, ModalOpenActionWithID} from "../_actions/modal.actions";


export const _ModalDefault: ModalModel = {
  status: false,
  type: null,
  close: true,
  _id: null,
};

@State<ModalModel>({
  name: 'APO_MODAL',
  defaults: _ModalDefault,
})
@Injectable()
export class ModalState implements NgxsOnInit, NgxsOnChanges {

  constructor(private store: Store) {}

  ngxsOnInit(ctx?: StateContext<any>): any {}
  ngxsOnChanges(change: NgxsSimpleChange<ModalModel>): void {}

  @Selector()
  static selectStatus(state: ModalModel) {
    return state;
  }
  @Selector()
  static selectOpenedID(state: ModalModel): string {
    return state._id;
  }

  @Action(ModalOpenAction)
  modalOpenAction(ctx: StateContext<ModalModel>, type) {
    const state = ctx.getState();
    if (state.status && type.data == state.type) {
      return;
    }
    ctx.patchState({
      ...state,
      status: true,
      type: type.data,
      close: type.close != undefined ? type.close : true,
    });
  }

  @Action(ModalOpenActionWithID)
  modalOpenActionWithID(ctx: StateContext<ModalModel>, type) {
    const state = ctx.getState();
    if (state.status && type.data == state.type) {
      this.store.dispatch(new ModalCloseAction());
      return;
    }
    ctx.patchState({
      ...state,
      status: true,
      type: type.data,
      close: type.close != undefined ? type.close : true,
      _id: type._id != undefined ? type._id : null,
    });
  }

  @Action(ModalCloseAction)
  modalCloseAction(ctx: StateContext<ModalModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      status: false,
      type: null,
      close: true,
    });
  }

}
