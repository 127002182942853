import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {TimerModel} from "../_models/timer.model";
import {Injectable} from "@angular/core";
import {InitTimers, StartTimer, StopTimer, UpdateTimer, WatchTimers} from "../_actions/timer.actions";
import {AuthAllowAccess, AuthDisallowAccess} from "../../auth/_actions/auth.actions";
import {UserState} from "../../user/_state/user.state";
import {UserService} from "../../user/_services/user.service";
import {UpdateUpEnergy, UpdateUpLife} from "../../user/_actions/user.actions";


export const _TimerDuration = {
  life: 25000, // 25000
  energy: 120000, // 120000 || 30000
  gift: 120000, // 120000
}

export const _TimerDefault: TimerModel = {
  energy: {
    _id: undefined,
    start: undefined,
    end: undefined,
  },
  life: {
    _id: undefined,
    end: undefined,
    start: undefined
  },
  gift: {
    _id: undefined,
    end: undefined,
    start: undefined
  }
};

@State<TimerModel>({
  name: 'APO_TIMERS',
  defaults: _TimerDefault,
})
@Injectable()
export class TimerState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  private watchId = null;

  constructor(private store: Store, private userService: UserService) {}

  ngxsAfterBootstrap(ctx: StateContext<any>): void {
    //this.observeBootstrapTimers(ctx);
  }
  ngxsOnInit(ctx?: StateContext<any>): any {
    //this.detectTimers(ctx);
  }
  ngxsOnChanges(change: NgxsSimpleChange<TimerModel>): void {

  }


  @Selector()
  static selectTimers(state: TimerModel) {
    return state;
  }

  @Selector()
  static selectEnergyTimer(state: TimerModel) {
    return {
      timer: state.energy,
      duration: _TimerDuration.energy
    };
  }
  @Selector()
  static selectLifeTimer(state: TimerModel) {
    return {
      timer: state.life,
      duration: _TimerDuration.life
    };
  }
  @Selector()
  static selectGiftTimer(state: TimerModel) {
    return {
      timer: state.gift,
      duration: _TimerDuration.gift
    };
  }




  @Action(AuthAllowAccess)
  authAllowAccess(ctx: StateContext<TimerModel>) {
    ctx.dispatch(new InitTimers());
  }
  @Action(AuthDisallowAccess)
  authDisallowAccess(ctx: StateContext<TimerModel>) {
    ctx.setState(_TimerDefault);
  }

  @Action(InitTimers)
  async initTimers(ctx: StateContext<TimerModel>) {
    const _state_user = this.store.selectSnapshot(UserState.selectUser);
    if (_state_user.role == 'user' || _state_user.role == 'premium') {
      const _timers = await this.userService.getTimers();
      if(_timers) {
        const _state        = ctx.getState();
        const _state_default = {..._TimerDefault};
        const _timer_energy = _timers.find((_t) => _t.type == 'energy');
        const _timer_life   = _timers.find((_t) => _t.type == 'life');
        const _timer_gift   = _timers.find((_t) => _t.type == 'gift');

        if(_timer_energy) {
          _state_default.energy = {
            _id: undefined,
            start: _timer_energy.start,
            end:_timer_energy.end
          }
        }
        if(_timer_life) {
          _state_default.life = {
            _id: undefined,
            start: _timer_life.start,
            end:_timer_life.end
          }
        }
        if(_timer_gift) {
          _state_default.gift = {
            _id: undefined,
            start: _timer_gift.start,
            end:_timer_gift.end
          }
        }
        ctx.setState(_state_default);
        this.runWatchTimers(ctx);
      }
    }
    else {
      this.runWatchTimers(ctx);
    }
  }

  @Action(StartTimer)
  startTimer(ctx: StateContext<TimerModel>, _data: any) {
    const _state = {...ctx.getState()};
    const _state_user = this.store.selectSnapshot(UserState.selectUser);

    let _timer = {..._state[_data.type]};

    const _time_current = new Date();
    const _time_timer_start = _timer.start? new Date(_timer.start): _timer.start;
    const _time_timer_end = _timer.end? new Date(_timer.end): _timer.end;

    const _time_start = _timer.start? _time_timer_start: _time_current;
    const _time_end = _timer.end? new Date(new Date().setTime(_time_timer_end.getTime() + _TimerDuration[_data.type])): new Date(new Date().setTime(_time_current.getTime() + _TimerDuration[_data.type]));

    _timer.start =_time_start;
    _timer.end =_time_end;

    _state[_data.type] = _timer;

    ctx.patchState(_state);

    if (_state_user.role == 'user' || _state_user.role == 'premium') {
      this.userService.startTimer({
        type: _data.type,
        start: _timer.start,
        end: _timer.end
      }).subscribe();
    }

  }

  @Action(UpdateTimer)
  updateTimer(ctx: StateContext<TimerModel>, _data: any) {
    const _state = {...ctx.getState()};
    const _state_user = this.store.selectSnapshot(UserState.selectUser);

    let _timer = {..._state[_data.type]};
    _timer.start =_data.start;
    _state[_data.type] = _timer;

    ctx.patchState(_state);

    if (_state_user.role == 'user' || _state_user.role == 'premium') {
      this.userService.updateTimer({
        type: _data.type,
        start: _timer.start,
        end: _timer.end
      }).subscribe();
    }

  }

  @Action(StopTimer)
  stopTimer(ctx: StateContext<TimerModel>, _data: any) {
    const _state = {...ctx.getState()};
    const _state_user = this.store.selectSnapshot(UserState.selectUser);

    _state[_data.type] = {..._TimerDefault[_data.type]};

    ctx.patchState(_state);

    if (_state_user.role == 'user' || _state_user.role == 'premium') {
      this.userService.updateTimer({
        type: _data.type,
        start: null,
        end: null
      }).subscribe();
    }

  }

  @Action(WatchTimers)
  async watchTimers(ctx: StateContext<TimerModel>) {
    const _state = {...ctx.getState()};
    Object.keys(_state).forEach((_timer_name)=> {
      if(_state[_timer_name].start) {
        const _time_now   = new Date();
        const _time_start = new Date(_state[_timer_name].start);
        const _time_end   = new Date(_state[_timer_name].end);

        let _recovery_start_end = Math.floor((_time_end.getTime() - _time_start.getTime()) / _TimerDuration[_timer_name]);
        let _recovery_start_now = Math.floor((_time_now.getTime() - _time_start.getTime()) / _TimerDuration[_timer_name]);
        let _recovery_now_end = Math.floor((_time_end.getTime() - _time_now.getTime()) / _TimerDuration[_timer_name]);

        if(_recovery_start_now > 0) {
          ctx.dispatch(new UpdateTimer(_timer_name,_time_now));
          if(_timer_name == 'energy') {
            ctx.dispatch(new UpdateUpEnergy(_recovery_start_now, true));
          }
          if(_timer_name == 'life') {
            ctx.dispatch(new UpdateUpLife(_recovery_start_now, true));
          }
        }
        if(_time_now > _time_end) {
          ctx.dispatch(new StopTimer(_timer_name));
          if(_timer_name == 'energy') {
            ctx.dispatch(new UpdateUpEnergy(1, true));
          }
          if(_timer_name == 'life') {
            ctx.dispatch(new UpdateUpLife(1, true));
          }
        }
      }
      else {

      }
    })
  }

  private runWatchTimers(ctx: StateContext<TimerModel>) {
    this.watchId = setInterval(() => {
      this.store.dispatch(new WatchTimers());
    }, 1000);
  }

}
