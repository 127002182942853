import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {RouterNavigated, RouterNavigation} from '@ngxs/router-plugin';

import {TranslocoService} from "@ngneat/transloco";
import {SeoService} from "../../../_services/seo.service";
import {CrumbsModel} from "../_models/crumbs.model";
import {AuthState} from "../../auth/_state/auth.state";
import {ModalCloseAction} from "../../modal/_actions/modal.actions";
import {take} from "rxjs";
import {WorkbooksState} from "../../workbooks/_state/workbooks.state";
import {WorkbookModel} from "../../workbooks/_models/workbooks.model";
import {ChangeLanguageAction, SetSoundAction} from "../../settings/_actions/settings.actions";
import {BlogState} from "../../blog/_state/blog.state";
import {WorksheetsState} from "../../worksheets/_state/worksheets.state";
import {SettingsModel} from "../../settings/_models/settings.model";
import {UpdateTitle} from "../_actions/crumbs.actions";
import {PracticesState} from "../../practice/_state/practice.state";


export const _CrumbsDefault: CrumbsModel = {
  title: ' ',
  subtitle: ' ',
  description: ' ',
  breadcrumbs: []
};

@State<CrumbsModel>({
  name: 'APO_CRUMBS',
  defaults: _CrumbsDefault,
})

@Injectable()
export class CrumbsState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  constructor(private store: Store, private transloco: TranslocoService, private seoService: SeoService) {}

  ngxsAfterBootstrap(ctx?: StateContext<CrumbsModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange): void {}

  ngxsOnInit(ctx?: StateContext<any>): any {}

  @Selector()
  static selectCrumbs(state: CrumbsModel) {
    return state;
  }

  @Action(RouterNavigated)
  routerRequest(ctx: StateContext<CrumbsModel>, payload) {
    ctx.dispatch(new ModalCloseAction());
  }


  @Action(RouterNavigation)
  async routerNavigation(ctx: StateContext<CrumbsModel>, payload) {
    const _crumb_url = payload.routerState.url.split('?')[0].split('#')[0];
    const _crumb_user_access = this.store.selectSnapshot(AuthState.selectAccess);
    let   _crumb_link = '/';
    let   _crumbs_translated  = {..._CrumbsDefault};
    const _global_translate = await this.transloco.selectTranslation().pipe(take(1)).toPromise();

    let _translate = {..._global_translate};

    const _window_locate = 'https://argoprep.online';


    _crumbs_translated.og = {
      "update": false,
      "title": _translate['nav.home_alt'] + " - " +_translate['global.app_name'],
      "description": _translate['global.app_description'],
      "site_name": _translate['global.app_name'],
      "image": _window_locate+"/assets/images/posters/",
      "image:alt": _window_locate+"/assets/images/posters/workbooks.png",
      "image:secure_url": _window_locate+"/assets/images/posters/default_dark.png"
    };


    if (_crumb_url === '/') {
      _crumbs_translated.title = _translate['nav.home_alt'];
      _crumbs_translated.title_alt = 'nav.home_alt';
      _crumbs_translated.description = _translate['global.app_description'];
      _crumbs_translated.og = {..._crumbs_translated.og, update: true, image: _crumbs_translated.og.image+'default_white_system.png'}
    }
    if (_crumb_url === '/k12/') {
      _crumbs_translated.title = _translate['nav.home_alt'];
      _crumbs_translated.title_alt = 'nav.home_alt';
      _crumbs_translated.description = _translate['global.app_description'];
      _crumbs_translated.og = {..._crumbs_translated.og, update: true, image: _crumbs_translated.og.image+'default_white_system.png'}
    }
    if (_crumb_url === '/shsat/') {
      _crumbs_translated.title = _translate['nav.shsat'];
      _crumbs_translated.title_alt = 'nav.shsat';
      _crumbs_translated.description = _translate['global.app_description'];
      _crumbs_translated.og = {..._crumbs_translated.og, update: true, image: _crumbs_translated.og.image+'default_white_system.png'}
    }
    if (_crumb_url === '/terms/') {
      _crumbs_translated.title = _translate['nav.terms'];
      _crumbs_translated.title_alt = 'nav.terms';
      _crumbs_translated.description = _translate['seo.description.terms'];
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: _translate['nav.terms'] + " - " +_translate['global.app_name'],
        description: _translate['seo.description.terms'],
        image: _crumbs_translated.og.image+'default_dark.png'
      }
    }
    if (_crumb_url === '/privacy-policy/') {
      _crumbs_translated.title = _translate['nav.privacy-policy'];
      _crumbs_translated.title_alt = 'nav.privacy-policy';
      _crumbs_translated.description = _translate['seo.description.privacy-policy'];
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: _translate['nav.privacy-policy'] + " - " +_translate['global.app_name'],
        description: _translate['seo.description.privacy-policy'],
        image: _crumbs_translated.og.image+'default_dark.png'
      }
    }
    if (_crumb_url.includes('/auth/')) {
      if(payload.routerState.params.action) {
        if(payload.routerState.params.token) {
          _crumbs_translated.title = _translate['nav.forgot-token'];
          _crumbs_translated.title_alt = 'nav.forgot-token';
        }
        else {
          _crumbs_translated.title = _translate['nav.'+payload.routerState.params.action];
          _crumbs_translated.title_alt = 'nav.'+payload.routerState.params.action;
        }
      }
      else {
        _crumbs_translated.title = _translate['nav.auth'];
        _crumbs_translated.title_alt = 'nav.auth';
        _crumbs_translated.description = _translate['global.app_description'];
        _crumbs_translated.og = {
          ..._crumbs_translated.og,
          update: true,
          title: _translate['nav.auth'] + " - " +_translate['global.app_name'],
          image: _crumbs_translated.og.image+'default_dark.png'
        }
      }
    }
    if (_crumb_url.includes('/book-video-explanation')) {
      if(payload.routerState.params.workbook_slug) {
       // _crumbs_translated.title = _translate['nav.'+payload.routerState.params.workbook_slug];
        _crumbs_translated.title_alt = 'nav.'+payload.routerState.params.workbook_slug;

        const _workbook = this.store.select(WorkbooksState.selectWorkbookBySlug(payload.routerState.params.workbook_slug)).subscribe((_workbook: WorkbookModel)=> {
          if(_workbook) {
            _crumbs_translated.title =  _workbook.title;
            _crumbs_translated.description = _workbook.description;
            _crumbs_translated.og = {
              ..._crumbs_translated.og,
              update: true,
              title: _workbook.title,
              description: _workbook.description,
              image:_workbook.thumbnail
            }
            this.seoService.updateTitle(_crumbs_translated.title);
            this.seoService.updateDescription(_crumbs_translated.description);
            this.seoService.updateOgMetaTags(_crumbs_translated.og);
          }
        });
      }
      else {
        _crumbs_translated.title = _translate['nav.book-video-explanation'];
        _crumbs_translated.title_alt = 'nav.book-video-explanation';
      }
    }
    if (_crumb_url.includes('/store')) {
      if(payload.routerState.params.workbook_slug) {
        _crumbs_translated.title_alt = 'nav.'+payload.routerState.params.workbook_slug;
        const _workbook = this.store.select(WorkbooksState.selectWorkbookBySlug(payload.routerState.params.workbook_slug)).subscribe((_workbook: WorkbookModel)=> {
          if(_workbook) {
            _crumbs_translated.title =  _workbook.title;
            _crumbs_translated.description = _workbook.description;
            _crumbs_translated.og = {
              ..._crumbs_translated.og,
              update: true,
              title: _workbook.title,
              description: _workbook.description,
              image:_workbook.thumbnail
            }
            this.seoService.updateTitle(_crumbs_translated.title);
            this.seoService.updateDescription(_crumbs_translated.description);
            this.seoService.updateOgMetaTags(_crumbs_translated.og);
          }
        });

      }
      else if(payload.routerState.params.serie) {
        _crumbs_translated.title = _translate['nav.'+payload.routerState.params.serie];
        _crumbs_translated.title_alt = 'nav.'+payload.routerState.params.serie;
      }
      else if (payload.routerState.url == '/store/cart/'){
        _crumbs_translated.title = _translate['nav.cart'];
        _crumbs_translated.title_alt = 'nav.cart';
      }
      else if (payload.routerState.url == '/store/checkout/'){
        _crumbs_translated.title = _translate['nav.checkout'];
        _crumbs_translated.title_alt = 'nav.checkout';
      }
      else if (payload.routerState.url == '/store/listing/'){
        _crumbs_translated.title = _translate['nav.listing'];
        _crumbs_translated.title_alt = 'nav.listing';
      }
      else if (_crumb_url.includes('/store/listing/')){
        if(payload.routerState.params.filter_grade) {
          _crumbs_translated.title = _translate['nav.'+payload.routerState.params.filter_grade];
          _crumbs_translated.title_alt = 'nav.'+payload.routerState.params.filter_grade;
        } else {
          _crumbs_translated.title = _translate['nav.listing'];
          _crumbs_translated.title_alt = 'nav.listing';
        }
      }
      else {
        _crumbs_translated.title = _translate['nav.store'];
        _crumbs_translated.title_alt = 'nav.store';
      }
    }
    if (_crumb_url === '/worksheets/') {
      _crumbs_translated.title = _translate['nav.worksheets'];
      _crumbs_translated.title_alt = 'nav.worksheets';
    }
    if (_crumb_url.includes('/worksheet')) {
      if(payload.routerState.params.worksheet_slug) {
        _crumbs_translated.title_alt = 'nav.'+payload.routerState.params.worksheet_slug;
        const _worksheet = this.store.select(WorksheetsState.selectWorksheetBySlug(payload.routerState.params.worksheet_slug)).subscribe((_worksheet: any)=> {
          if(_worksheet) {
            this.transloco.setTranslation({['nav.' + _worksheet.slug + '']: _worksheet.title});
            _crumbs_translated.title =  _worksheet.title;
            _crumbs_translated.description = _worksheet.description;
            _crumbs_translated.og = {
              ..._crumbs_translated.og,
              update: true,
              title: _worksheet.title,
              description: _worksheet.description,
              image:_worksheet.thumbnail
            }
            this.seoService.updateTitle(_crumbs_translated.title);
            this.seoService.updateDescription(_crumbs_translated.description);
            this.seoService.updateOgMetaTags(_crumbs_translated.og);
          }
        });

      }
      else {
        _crumbs_translated.title = _translate['nav.worksheets'];
        _crumbs_translated.title_alt = 'nav.worksheets';
      }
    }

    if (_crumb_url === '/account/') {
      _crumbs_translated.title = _translate['nav.account-info'];
      _crumbs_translated.title_alt = 'nav.account-info';
    }
    if (_crumb_url === '/account/children/') {
      _crumbs_translated.title = _translate['nav.account-children'];
      _crumbs_translated.title_alt = 'nav.account-children';
    }
    if (_crumb_url === '/account/subscriptions/') {
      _crumbs_translated.title = _translate['nav.account-subscriptions'];
      _crumbs_translated.title_alt = 'nav.account-subscriptions';
    }
    if (_crumb_url === '/account/purchases/') {
      _crumbs_translated.title = _translate['nav.account-purchases'];
      _crumbs_translated.title_alt = 'nav.account-purchases';
    }
    if (_crumb_url === '/account/workbooks/') {
      _crumbs_translated.title = _translate['nav.account-workbooks'];
      _crumbs_translated.title_alt = 'nav.account-workbooks';
    }
    if (_crumb_url === '/account/downloads/') {
      _crumbs_translated.title = _translate['nav.account-downloads'];
      _crumbs_translated.title_alt = 'nav.account-downloads';
    }
    if (_crumb_url === '/account/awards/') {
      _crumbs_translated.title = _translate['nav.account-awards'];
      _crumbs_translated.title_alt = 'nav.account-awards';
    }
    if (_crumb_url.includes('/blog')) {
      if(payload.routerState.params.category) {
        const _product_translate = await this.transloco.selectTranslation('pages/blog/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
        _translate = {..._translate, ..._product_translate};

        _crumbs_translated.title = _translate['nav.blog']+' - '+_translate['nav.'+payload.routerState.params.category];
        _crumbs_translated.title_alt = 'seo.nav.blog.'+payload.routerState.params.category;
        _crumbs_translated.description = _translate['seo.blog_'+payload.routerState.params.category];
        _crumbs_translated.og = {
          ..._crumbs_translated.og,
          update: true,
          title: _translate['nav.blog']+' - '+_translate['seo.nav.blog.'+payload.routerState.params.category] + " - " +_translate['global.app_name'],
          description: _translate['seo.blog_'+payload.routerState.params.category],
          image: _crumbs_translated.og.image+'default.png'
        }
      }
      else if(payload.routerState.params.page_num && payload.routerState.params.page_num > 1) {
        const _product_translate = await this.transloco.selectTranslation('pages/blog/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
        _translate = {..._translate, ..._product_translate};

        _crumbs_translated.title = _translate['seo.titles.blog'] +':'+_translate['nav.page'] + ' '+ payload.routerState.params.page_num;
        _crumbs_translated.title_alt = 'nav.blog';
        _crumbs_translated.description = _translate['seo.blog'];
        _crumbs_translated.og = {
          ..._crumbs_translated.og,
          update: true,
          title: _translate['seo.titles.blog'] + " - " +_translate['global.app_name'],
          description: _translate['seo.blog'],
          image: _crumbs_translated.og.image+'default_dark.png'
        }
      }
      else if (payload.routerState.params.post) {
        const _post = this.store.select(BlogState.selectPostBySlug(payload.routerState.params.post)).subscribe((_post: any)=> {
          if(_post) {
            this.transloco.setTranslation({['nav.' + _post.slug + '']: _post.title});
            _crumbs_translated.title =  _post.title;
            _crumbs_translated.title_alt = 'nav.'+payload.routerState.params.post;
            _crumbs_translated.description = _post.excerpt;
            _crumbs_translated.og = {
              ..._crumbs_translated.og,
              update: true,
              title: _post.title,
              description: _post.excerpt,
              image:_post.thumbnail.src
            }
            this.seoService.updateTitle(_crumbs_translated.title);
            this.seoService.updateDescription(_crumbs_translated.description);
            this.seoService.updateOgMetaTags(_crumbs_translated.og);
          }
        });

      }
      else {
        const _blog_translate = await this.transloco.selectTranslation('pages/blog/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
        _translate = {..._translate, ..._blog_translate};

        _crumbs_translated.title = _translate['seo.titles.blog'];
        _crumbs_translated.title_alt = 'nav.blog';
        _crumbs_translated.description = _translate['seo.blog'];
        _crumbs_translated.og = {
          ..._crumbs_translated.og,
          update: true,
          title: _translate['seo.titles.blog'] + " - " +_translate['global.app_name'],
          description: _translate['seo.blog'],
          image: _crumbs_translated.og.image+'default_dark.png'
        }

      }
    }
    if (_crumb_url === '/dashboard/') {
      _crumbs_translated.title = _translate['nav.dashboard'];
      _crumbs_translated.title_alt = 'nav.dashboard';
    }
    if (_crumb_url === '/practices/') {
      _crumbs_translated.title = _translate['nav.practices'];
      _crumbs_translated.title_alt = 'nav.practices';
    }
    if (_crumb_url === '/practices/practice_drills/') {
      _crumbs_translated.title = _translate['nav.practice_drills'];
      _crumbs_translated.title_alt = 'nav.practice_drills';
    }
    if (_crumb_url === '/practices/practice_questions/') {
      _crumbs_translated.title = _translate['nav.practice_questions'];
      _crumbs_translated.title_alt = 'nav.practice_questions';
    }
    if (_crumb_url === '/practices/video_lectures/') {
      _crumbs_translated.title = _translate['nav.video_lectures'];
      _crumbs_translated.title_alt = 'nav.video_lectures';
    }
    if (_crumb_url === '/practices/worksheets/') {
      _crumbs_translated.title = _translate['nav.worksheets'];
      _crumbs_translated.title_alt = 'nav.worksheets';
    }

    if (_crumb_url.includes('/practices/')) {
      if(payload.routerState.params.lectures_id) {
        this.store.select(PracticesState.selectPracticeById(payload.routerState.params.lectures_id)).subscribe((_practice: any)=> {
          this.transloco.setTranslation({['nav.' + payload.routerState.params.lectures_id + '']: _practice.title});
          _crumbs_translated.title =  _practice.title;
          _crumbs_translated.title_alt = 'nav.'+payload.routerState.params.lectures_id;
        });
      }
      if(payload.routerState.params.quiz_id) {
        this.store.select(PracticesState.selectPracticeById(payload.routerState.params.quiz_id)).subscribe((_practice: any)=> {
          this.transloco.setTranslation({['nav.' + payload.routerState.params.quiz_id + '']: _practice.title});
          _crumbs_translated.title =  _practice.title;
          _crumbs_translated.title_alt = 'nav.'+payload.routerState.params.quiz_id;
        });
      }
    }



/*    if (_crumb_url === '/ua/') {
      this.transloco.setActiveLang('ua');
      this.store.dispatch(new ChangeLanguageAction('ua'));
      _crumbs_translated.title = 'Безкоштовні уроки та практика для домашнього навчання онлайн';
      _crumbs_translated.description = 'Це інноваційна освітня платформа для дошкільнят, яка пропонує тисячі розвивальних завдань та робочих аркушів для дітей. Наші ресурси допомагають розвивати навички читання, письма, математики, одночасно стимулюючи інтерес до навчання та науки.';
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        url: 'https://argoprep.online/ua/',
        title: 'Безкоштовні уроки та практика для домашнього навчання онлайн в '  + " - " +_translate['global.app_name'],
        description: 'Це інноваційна освітня платформа для дошкільнят, яка пропонує тисячі розвивальних завдань та робочих аркушів для дітей. Наші ресурси допомагають розвивати навички читання, письма, математики, одночасно стимулюючи інтерес до навчання та науки.',
        image: _crumbs_translated.og.image+'default_ua.png'
      }
    }
    if (_crumb_url === '/contacts/') {
      _crumbs_translated.title = _translate['nav.contacts'];
      _crumbs_translated.title_alt = 'nav.contacts';
      _crumbs_translated.description = _translate['seo.description.contacts'];
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: _translate['nav.contacts'] + " - " +_translate['global.app_name'],
        description: _translate['seo.description.contacts'],
        image: _crumbs_translated.og.image+'default_dark.png'
      }
    }
    if (_crumb_url.includes('/search/')) {
      _crumbs_translated.title = _translate['nav.search'];
      _crumbs_translated.title_alt = 'nav.search';
      _crumbs_translated.description = _translate['seo.description.search'];
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: _translate['nav.search'] + " - " +_translate['global.app_name'],
        description: _translate['seo.description.search'],
        image: _crumbs_translated.og.image+'default_dark.png'
      }
    }

    if (_crumb_url.includes('/invite/')) {
      if(payload.routerState.params.uin) {
        _crumbs_translated.title = _translate['nav.invite'];
        _crumbs_translated.title_alt = 'nav.invite';
        _crumbs_translated.description = _translate['global.app_description'];
        _crumbs_translated.og = {
          ..._crumbs_translated.og,
          update: true,
          title: _translate['nav.invite'] + " - " +_translate['global.app_name'],
          image: _crumbs_translated.og.image+'default_dark.png'
        }
      }
    }


    if (_crumb_url === '/departments/') {
      _crumbs_translated.title = _translate['nav.departments'];
      _crumbs_translated.title_alt = 'nav.departments';
    }
    if (_crumb_url === '/rating/') {
      _crumbs_translated.title = _translate['nav.rating-full'];
      _crumbs_translated.title_alt = 'nav.rating-full';
    }



    if (_crumb_url.includes('/task')) {
      _crumbs_translated.title = _translate['nav.tasks'];
    }

    if (_crumb_url.includes('/worksheet-generators')) {
      if(payload.routerState.params.category) {
        if(payload.routerState.params.type) {
          const _worksheets_translate = await this.transloco.selectTranslation('pages/worksheet-generators/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
          _translate = {..._translate, ..._worksheets_translate};

          _crumbs_translated.title = _translate['nav.'+payload.routerState.params.type]+' '+_translate['nav.worksheet-generator'];
          _crumbs_translated.title_alt = 'seo.nav.'+payload.routerState.params.type;
          _crumbs_translated.description = _translate['items.'+payload.routerState.params.category+'.'+payload.routerState.params.type+'.description'];
          _crumbs_translated.og = {
            ..._crumbs_translated.og,
            update: true,
            title: _translate['nav.'+payload.routerState.params.type] +' '+_translate['nav.worksheet-generator'] + " - " +_translate['global.app_name'],
            description: _translate['items.'+payload.routerState.params.category+'.'+payload.routerState.params.type+'.description'],
            image: _crumbs_translated.og.image+'worksheet-generators-'+payload.routerState.params.category+'-'+payload.routerState.params.type+'.png'
          }
        }
        else {
          const _worksheets_translate = await this.transloco.selectTranslation('pages/worksheet-generators/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
          _translate = {..._translate, ..._worksheets_translate};

          _crumbs_translated.title = _translate['nav.'+payload.routerState.params.category]+' '+_translate['nav.worksheet-generators'];
          _crumbs_translated.title_alt = 'seo.nav.'+payload.routerState.params.category;
          _crumbs_translated.description = _translate['categories.'+payload.routerState.params.category+'.description'];
          _crumbs_translated.og = {
            ..._crumbs_translated.og,
            update: true,
            title: _translate['nav.'+payload.routerState.params.category] +' '+_translate['nav.worksheet-generators'] + " - " +_translate['global.app_name'],
            description: _translate['categories.'+payload.routerState.params.category+'.description'],
            image: _crumbs_translated.og.image+'worksheet-generators-'+payload.routerState.params.category+'.png'
          }
        }
      }
      else {
        const _worksheets_translate = await this.transloco.selectTranslation('pages/worksheet-generators/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
        _translate = {..._translate, ..._worksheets_translate};
        _crumbs_translated.title = _translate['seo.nav.worksheet-generators'];
        _crumbs_translated.title_alt = 'seo.nav.worksheet-generators_alt';
        _crumbs_translated.description = _translate['text'];
        _crumbs_translated.og = {
          ..._crumbs_translated.og,
          update: true,
          title: _translate['seo.nav.worksheet-generators'] + " - " +_translate['global.app_name'],
          description: _translate['text'],
          image: _crumbs_translated.og.image+'worksheet-generators.png'
        }

      }
    }
    if (_crumb_url.includes('/workbooks')) {
      if(payload.routerState.params.category) {
        if(payload.routerState.params.workbook) {
          const _product_translate = await this.transloco.selectTranslation('pages/workbook/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
          _translate = {..._translate, ..._product_translate};
          if(this.transloco.getActiveLang() == 'en') {
            _crumbs_translated.title = _translate['nav.'+payload.routerState.params.workbook] +' Preschool Activity '+_translate['nav.workbook'];
            _crumbs_translated.title_alt = _translate['nav.'+payload.routerState.params.workbook] +' Preschool Activity '+_translate['nav.workbook'];
          }
          if(this.transloco.getActiveLang() == 'ua') {
            _crumbs_translated.title = _translate['nav.workbook'] +' '+_translate['nav.'+payload.routerState.params.workbook];
            _crumbs_translated.title_alt = _translate['nav.workbook'] +' '+_translate['nav.'+payload.routerState.params.workbook];
          }
          if(this.transloco.getActiveLang() == 'ru') {
            _crumbs_translated.title = _translate['nav.workbook'] +' '+_translate['nav.'+payload.routerState.params.workbook];
            _crumbs_translated.title_alt = _translate['nav.workbook'] +' '+_translate['nav.'+payload.routerState.params.workbook];
          }

           const _workbook = this.store.select(WorkbooksState.selectWorkbookBySlug(payload.routerState.params.workbook)).subscribe((_workbook: WorkbookModel)=> {
             if(_workbook) {
               _crumbs_translated.description = _translate[_workbook.description.replace('pages.workbooks.', '')];
               _crumbs_translated.og = {
                 ..._crumbs_translated.og,
                 update: true,
                 title: _translate['nav.'+payload.routerState.params.workbook] + " - " +_translate['global.app_name'],
                 description: _translate[_workbook.description.replace('pages.workbooks.', '')],
                 image: _crumbs_translated.og.image+'workbooks-'+payload.routerState.params.workbook+'.png'
               }
               this.seoService.updateDescription(_crumbs_translated.description);
               this.seoService.updateOgMetaTags(_crumbs_translated.og);
             }
           });
        }
        else {
          const _product_translate = await this.transloco.selectTranslation('pages/workbook/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
          _translate = {..._translate, ..._product_translate};
          _crumbs_translated.title = _translate['nav.series.'+payload.routerState.params.category];
          _crumbs_translated.title_alt = _translate['nav.series.'+payload.routerState.params.category];
          _crumbs_translated.description = _translate['shop.series.items.'+payload.routerState.params.category+'.text'];
          _crumbs_translated.og = {
            ..._crumbs_translated.og,
            update: true,
            title: _translate['nav.series.'+payload.routerState.params.category] + " - " +_translate['global.app_name'],
            description: _translate['shop.series.items.'+payload.routerState.params.category+'.text'],
            image: _crumbs_translated.og.image+'workbooks-'+payload.routerState.params.category+'.png'
          }

        }
      }
      else {
        const _product_translate = await this.transloco.selectTranslation('pages/workbook/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
        _translate = {..._translate, ..._product_translate};

        _crumbs_translated.title = _translate['seo.nav.workbooks'];
        _crumbs_translated.title_alt = 'seo.nav.workbooks_alt';
        _crumbs_translated.description = _translate['shop.sub_title'];
        _crumbs_translated.og = {
          ..._crumbs_translated.og,
          update: true,
          title: _translate['seo.nav.workbooks'] + " - " +_translate['global.app_name'],
          description: _translate['shop.sub_title'],
          image: _crumbs_translated.og.image+'workbooks.png'
        }

      }
    }
    if (_crumb_url.includes('/blog')) {
      if(payload.routerState.params.category) {
        const _product_translate = await this.transloco.selectTranslation('pages/blog/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
        _translate = {..._translate, ..._product_translate};

        _crumbs_translated.title = _translate['nav.blog']+' - '+_translate['nav.'+payload.routerState.params.category];
        _crumbs_translated.title_alt = 'seo.nav.blog.'+payload.routerState.params.category;
        _crumbs_translated.description = _translate['seo.blog_'+payload.routerState.params.category];
        _crumbs_translated.og = {
          ..._crumbs_translated.og,
          update: true,
          title: _translate['nav.blog']+' - '+_translate['seo.nav.blog.'+payload.routerState.params.category] + " - " +_translate['global.app_name'],
          description: _translate['seo.blog_'+payload.routerState.params.category],
          image: _crumbs_translated.og.image+'default.png'
        }
      }
      else if(payload.routerState.params.page_num && payload.routerState.params.page_num > 1) {
        const _product_translate = await this.transloco.selectTranslation('pages/blog/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
        _translate = {..._translate, ..._product_translate};

        _crumbs_translated.title = _translate['seo.titles.blog'] +':'+_translate['nav.page'] + ' '+ payload.routerState.params.page_num;
        _crumbs_translated.title_alt = 'nav.blog';
        _crumbs_translated.description = _translate['seo.blog'];
        _crumbs_translated.og = {
          ..._crumbs_translated.og,
          update: true,
          title: _translate['seo.titles.blog'] + " - " +_translate['global.app_name'],
          description: _translate['seo.blog'],
          image: _crumbs_translated.og.image+'default_dark.png'
        }
      }
      else if (payload.routerState.params.post) {
        const _post = this.store.select(BlogState.selectPostBySlug(payload.routerState.params.post)).subscribe((_post: any)=> {
          if(_post) {
            this.transloco.setTranslation({['nav.' + _post.slug + '']: _post.title});
            _crumbs_translated.title =  _post.title;
            _crumbs_translated.title_alt = 'nav.'+payload.routerState.params.post;
            _crumbs_translated.description = _post.excerpt;
            _crumbs_translated.og = {
              ..._crumbs_translated.og,
              update: true,
              title: _post.title,
              description: _post.excerpt,
              image:_post.thumbnail.src
            }
            this.seoService.updateTitle(_crumbs_translated.title);
            this.seoService.updateDescription(_crumbs_translated.description);
            this.seoService.updateOgMetaTags(_crumbs_translated.og);
          }
        });

      }
      else {
        const _blog_translate = await this.transloco.selectTranslation('pages/blog/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
        _translate = {..._translate, ..._blog_translate};

        _crumbs_translated.title = _translate['seo.titles.blog'];
        _crumbs_translated.title_alt = 'nav.blog';
        _crumbs_translated.description = _translate['seo.blog'];
        _crumbs_translated.og = {
          ..._crumbs_translated.og,
          update: true,
          title: _translate['seo.titles.blog'] + " - " +_translate['global.app_name'],
          description: _translate['seo.blog'],
          image: _crumbs_translated.og.image+'default_dark.png'
        }

      }
    }*/

    _crumbs_translated.breadcrumbs = _crumb_url.split('/').filter(crumb => crumb.length > 1 && crumb != payload.routerState.params.token).reduce((crumbs_array, item) => {
      _crumb_link += item +'/';
      let _item_crumb: { name: any; page_num?: any; translate: any; translate_alt?: any; link: string; };

      if(item == 'hunter'){
        item = 'your-hunter';
      }
      if(item == 'page' && payload.routerState.params.page_num){
        _item_crumb = {
          name: item,
          page_num: payload.routerState.params.page_num,
          translate: _translate['nav.'+item],
          link: _crumb_link+payload.routerState.params.page_num
        }
      }
      else if(payload.routerState.params.post){
        _item_crumb = {
          name: item,
          page_num: payload.routerState.params.page_num,
          translate: _translate['nav.'+item],
          translate_alt: _translate['nav.'+item],
          link: _crumb_link.slice(0, -1)
        }
      }
      else {
        _item_crumb = {
          name: item,
          translate: _translate['nav.'+item],
          link: _crumb_link.slice(0, -1)
        }
      }


      return [...crumbs_array, _item_crumb];
    }, []);

    this.seoService.updateTitle(_crumbs_translated.title);
    this.seoService.updateDescription(_crumbs_translated.description);
    this.seoService.updateOgMetaTags(_crumbs_translated.og);
    this.seoService.updateCanonicalURL(_crumb_url, payload.routerState.data);
    this.seoService.updateNoIndexUrl(payload.routerState.data);


    if (_crumb_url === '/') {
      this.seoService.initSchema('bh-org-schema');
      this.seoService.updateSchemaOrganization();
      //this.seoService.initSchema('bh-website-schema');
      //this.seoService.updateSchemaWebsite();
      this.seoService.initSchema('bh-course-schema');
      this.seoService.updateSchemaCourse();
    } if (_crumb_url === '/ua/') {
      this.seoService.clearSchemaOrganization();
      //this.seoService.clearSchemaWebsite();
      this.seoService.clearSchemaCourse();
    } else {
      if(_crumb_url != '/') {
        this.seoService.clearSchemaOrganization();
        //this.seoService.clearSchemaWebsite();
        this.seoService.clearSchemaCourse();
      }
      this.seoService.initSchema('bh-breadcrumb-schema');
      this.seoService.initSchema('bh-product-schema');
    }
    if(!_crumb_url.includes('/workbooks')) {
      this.seoService.clearSchemaProduct();
    }
    if(_crumb_url.includes('/workbooks') && !payload.routerState.params.category) {
      this.seoService.clearSchemaProduct();
    }
    if(_crumb_url.includes('/workbooks') && payload.routerState.params.category && !payload.routerState.params.workbook) {
      this.seoService.clearSchemaProduct();
    }


    if (_crumb_url !== '/') {
      this.seoService.updateSchemaBreadcrumbs(_crumbs_translated);
    }

    ctx.patchState(_crumbs_translated);
  }

  @Action(UpdateTitle)
  public updateTitle(ctx: StateContext<CrumbsModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      title:  payload.title,
    });
  }
}


