export class LoadPracticesCategory  {
    public static readonly type = '[Practices] LoadPracticesCategory';
    constructor() {}
}
export class LoadPracticesVideo  {
    public static readonly type = '[Practices] LoadPracticesVideo';
    constructor() {}
}
export class LoadPracticesQuiz  {
    public static readonly type = '[Practices] LoadPracticesQuiz';
    constructor() {}
}
