import {
  Action, createSelector,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {WorksheetsModel} from "../_models/worksheets.model";
import {InitWorksheetsCategories, InitWorksheetsItems} from "../_actions/worksheets.actions";
import {InitWorkbooksReviews} from "../../workbooks/_actions/workbooks.actions";
import {WorkbooksService} from "../../workbooks/_services/workbooks.service";
import {WorksheetsService} from "../_services/worksheets.service";
import {TranslocoService} from "@ngneat/transloco";
import {WorkbookModel} from "../../workbooks/_models/workbooks.model";


export const _WorksheetsDefault: WorksheetsModel = {
  categories: [],
  items: [],
};

@State<WorksheetsModel>({
  name: 'APO_WORKSHEETS',
  defaults: _WorksheetsDefault,
})
@Injectable()
export class WorksheetsState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private worksheetsService: WorksheetsService, private transloco: TranslocoService) {}

  ngxsAfterBootstrap(ctx: StateContext<any>): void {
    ctx.dispatch(new InitWorksheetsItems());
  }

  ngxsOnInit(ctx?: StateContext<any>): any {

  }
  ngxsOnChanges(change: NgxsSimpleChange<WorksheetsModel>): void {}


  @Selector()
  static selectWorksheets( state: WorksheetsModel) {
    return state.items;
  }
  static selectWorksheetBySlug( _slug: string ) {
    return createSelector([ WorksheetsState.selectWorksheets ], ( _worksheets: any[]) => {
      return _worksheets.find(x => x.slug === _slug);
    });
  }
  @Selector()
  static selectCategories( state: WorksheetsModel) {
    return state.categories;
  }


  @Action(InitWorksheetsCategories)
  public initWorksheetsCategories(ctx: StateContext<WorksheetsModel>) {
    const _state = ctx.getState();
    const _categories =  [
      {
        _id: null,
        title: 'pages.worksheet-generators.categories.math.title',
        description: 'pages.worksheet-generators.categories.math.description',
        thumbnail: 'bg_wg_1.svg',
        slug: 'math'

      },
      {
        _id: null,
        title: 'pages.worksheet-generators.categories.reading.title',
        description: 'pages.worksheet-generators.categories.reading.description',
        thumbnail: 'bg_wg_0.svg',
        slug: 'reading'

      },
      {
        _id: null,
        title: 'pages.worksheet-generators.categories.tracing.title',
        description: 'pages.worksheet-generators.categories.tracing.description',
        thumbnail: 'bg_wg_2.svg',
        slug: 'tracing'

      },
      {
        _id: null,
        title: 'pages.worksheet-generators.categories.logic.title',
        description: 'pages.worksheet-generators.categories.logic.description',
        thumbnail: 'bg_wg_3.svg',
        slug: 'logic'

      },
    ];
    ctx.patchState({
      ..._state,
      categories: _categories
    });
  }

  @Action(InitWorksheetsItems)
  async initWorksheetsItems(ctx: StateContext<WorksheetsModel>) {
    const _state = ctx.getState();
    let _worksheets = await this.worksheetsService.getWorksheets();
    ctx.patchState({
      ..._state,
      items: _worksheets
    });


/*    _worksheets.forEach((_worksheet) => {
      let _k = 'nav.' + _worksheet.slug + '';
      let _v = _worksheet.title;
      this.transloco.setTranslation({[_k]: _v});
    });*/
  }

}
