import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {ReviewModel, WorkbookModel, WorkbooksModel} from "../_models/workbooks.model";
import {InitWorkbookBve, InitWorkbooksItems, InitWorkbooksReviews} from "../_actions/workbooks.actions";
import {WorkbooksService} from "../_services/workbooks.service";
import {default as _REVIEWS} from '../../../../assets/json/_reviews.json';
import {TranslocoService} from "@ngneat/transloco";
import {SetSoundAction} from "../../settings/_actions/settings.actions";
import {SettingsModel} from "../../settings/_models/settings.model";

export const _WorkbooksDefault: WorkbooksModel = {
  items: [],
  reviews:[]
};

@State<WorkbooksModel>({
  name: 'APO_WORKBOOKS',
  defaults: _WorkbooksDefault,
})
@Injectable()
export class WorkbooksState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private store: Store, private workbooksService: WorkbooksService, private transloco: TranslocoService) {}

  ngxsOnInit(ctx?: StateContext<WorkbooksModel>): any {}
  ngxsOnChanges(change: NgxsSimpleChange<WorkbooksModel>): void {}
  ngxsAfterBootstrap(ctx: StateContext<WorkbooksModel>) {
    ctx.dispatch(new InitWorkbooksItems());
  }

  @Selector()
  static selectWorkbooks( state: WorkbooksModel) {
    return state.items;
  }
  @Selector()
  static selectBveWorkbooks(state: WorkbooksModel) {
    const _bve_series = [
      "social studies",
      "kids summer academy",
      "brain hunter",
      "spelling",
      "common core spanish",
      "introducing math science",
      "science",
      "kids winter academy",
      "math practice",
      "common core",
      "introducing math",
      "shsat"
    ];
    return state.items.filter(item => _bve_series.includes(item.series.split('-').join(' ').replace(' series', '')));
  }
  @Selector()
  static selectStoreWorkbooks(state: WorkbooksModel) {
    const _store_series = ['social studies', 'brain hunter', 'kids summer academy', 'spelling', 'common core spanish', 'introducing math science', 'pre school', 'tracing', 'science', 'kids winter academy', 'math practice', 'common core', 'introducing math', 'shsat'];
    return state.items.filter(item => _store_series.includes(item.series.split('-').join(' ').replace(' series', '')));
  }


  @Selector()
  static selectWorkbooksReview( state: WorkbooksModel) {
    return state.reviews;
  }
  @Selector()
  static selectWorkbooksSeries(state: WorkbooksModel) {
    const series = state.items
        .map(_series => {
          return _series.series ? _series.series.split('-').join(' ').replace(' series', '') : null;
        })
        .filter(Boolean); // Фильтрация null и пустых значений
    return [...new Set(series)];
  }
  @Selector()
  static selectWorkbooksSeriesWithWorkbooks(state: WorkbooksModel) {
    const _store_series = [
      'social studies', 'brain hunter', 'kids summer academy', 'spelling',
      'common core spanish', 'introducing math science', 'pre school', 'tracing',
      'science', 'kids winter academy', 'math practice', 'common core',
      'introducing math', 'shsat'
    ];
    const workbooksBySeries = state.items.reduce((acc, item) => {
      const seriesName = item.series.split('-').join(' ').replace(' series', '');

      if (_store_series.includes(seriesName)) {
        if (!acc[seriesName]) {
          acc[seriesName] = [];
        }
        acc[seriesName].push(item);
      }
      return acc;
    }, {} as { [key: string]: any[] });
    return Object.entries(workbooksBySeries).map(([series, workbooks]) => ({
      slug: series.replace(/ /g, '_').toLowerCase(),
      series,
      workbooks
    }));
  }

  static selectWorkbookByLookInside() {
    const look_inside = [
      { slug: 'kindergarten', workbooks: [15994, 16007, 20640, 43987] },
      { slug: '1st-grade', workbooks: [43990, 17971, 15963, 12132] },
      { slug: '2nd-grade', workbooks: [13474, 12135, 45647, 43989] },
      { slug: '3rd-grade', workbooks: [13510, 12141, 27056, 43988] },
      { slug: '4th-grade', workbooks: [13584, 12145, 27038, 47323] },
      { slug: '5th-grade', workbooks: [13621, 12150, 26966, 92951] },
      { slug: '6th-grade', workbooks: [13687, 12154, 27078, 116907] },
      { slug: '7th-grade', workbooks: [13720, 12161, 26984, 99219] },
      { slug: '8th-grade', workbooks: [13732, 12164, 20652, 66692] },
    ];
    return createSelector([WorkbooksState.selectWorkbooks], (_workbooks: WorkbookModel[]) => {
      // Создаем мапу для быстрого доступа к объектам _workbooks по их _id
      const workbooksMap = new Map(_workbooks.map(workbook => [workbook._id.toString(), workbook]));

      // Заменяем идентификаторы на объекты
      const updatedLookInside = look_inside.map(grade => {
        const updatedWorkbooks = grade.workbooks
            .map(workbookId => workbooksMap.get(workbookId.toString()))
            .filter(workbook => workbook !== undefined); // Фильтруем undefined, если workbook не найден

        return {
          ...grade,
          workbooks: updatedWorkbooks
        };
      });

      return updatedLookInside;
    });
  }


  static selectWorkbookBySlug( _slug: string ) {
    return createSelector([ WorkbooksState.selectWorkbooks ], ( _workbooks: WorkbookModel[]) => {
      return _workbooks.find(x => x.slug === _slug);
    });
  }
  static selectReviewsByWorkbookId( _id: string ) {
    return createSelector([ WorkbooksState.selectWorkbooksReview ], ( _reviews: ReviewModel[]) => {
      return _reviews.filter(x => x.product_id === _id);
    });
  }


  @Action(InitWorkbooksItems)
  async initWorkbooksItems(ctx: StateContext<WorkbooksModel>) {
    const _state = ctx.getState();
    let _workbooks = await this.workbooksService.getWorkbooks();
    ctx.patchState({
      ..._state,
      items: _workbooks
    });
    ctx.dispatch(new InitWorkbooksReviews());

    _workbooks.forEach((_workbook) => {
      let _k = 'nav.' + _workbook.slug + '';
      let _v = _workbook.title;
      this.transloco.setTranslation({[_k]: _v});
    });
  }

  @Action(InitWorkbooksReviews)
  public initWorkbooksReviews(ctx: StateContext<WorkbooksModel>) {
    const _state = ctx.getState();
    const _reviews = _REVIEWS;
    ctx.patchState({
      ..._state,
      reviews: _reviews
    });
  }


  @Action(InitWorkbookBve)
  public initWorkbookBve(ctx: StateContext<WorkbooksModel>, payload) {

  }

}
