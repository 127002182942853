import {API} from "../../api";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {CategoryModel} from "../_models/practice.model";



@Injectable({
  providedIn: 'root',
})
export class PracticesService {

  constructor(private http: HttpClient) {
  }

  public getGradePractice(grade_id: string) {
    const _url = API.url('practice/tree/'+grade_id, true);
    return this.http.get<CategoryModel[]>(_url).toPromise();
  }
  public getPracticeVideos(lectures_id: string) {
    const _url = API.url('videos/'+lectures_id);
    return this.http.get<any[]>(_url).toPromise();
  }
  public getPracticeQuiz(quiz_id: string) {
    const _url = API.url('questions/parent/'+quiz_id);
    return this.http.get<any[]>(_url).toPromise();
  }
}
