import {createSelector, NgxsOnChanges, NgxsOnInit, NgxsSimpleChange, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {GradesModel} from "../_models/grades.model";


export const _GradesDefault: GradesModel = {
  list: [
    {
      "_id": "62f515fbd62293f5fddcaca0",
      "title": "Kindergarten",
      "description": "",
      "slug": "kindergarten",
      "thumbnail": "",
      "order": 0,
      "active": true,
      "category": "5e703269f73e83064765eb0f"
    },
    {
      "_id": "62f515fbd62293f5fddcac98",
      "title": "1st grade",
      "description": "",
      "slug": "1st_grade",
      "thumbnail": "",
      "order": 1,
      "active": true,
      "category": "5b8b654ebe908d3c41bf436e"
    },
    {
      "_id": "62f515fbd62293f5fddcac99",
      "title": "2nd grade",
      "description": "",
      "slug": "2nd_grade",
      "thumbnail": "",
      "order": 2,
      "active": true,
      "category": "5ae9ac9a10e927a617919f91"
    },
    {
      "_id": "62f515fbd62293f5fddcac9a",
      "title": "3rd grade",
      "description": "",
      "slug": "3rd_grade",
      "thumbnail": "",
      "order": 3,
      "active": true,
      "category": "5ae9ac9a10e927a617919f94"
    },
    {
      "_id": "62f515fbd62293f5fddcac9b",
      "title": "4th grade",
      "description": "",
      "slug": "4th_grade",
      "thumbnail": "",
      "order": 4,
      "active": true,
      "category": "5ae9ac9a10e927a617919f97"
    },
    {
      "_id": "62f515fbd62293f5fddcac9c",
      "title": "5th grade",
      "description": "",
      "slug": "5th_grade",
      "thumbnail": "",
      "order": 5,
      "active": true,
      "category": "5ae9ac9a10e927a617919f9a"
    },
    {
      "_id": "62f515fbd62293f5fddcac9d",
      "title": "6th grade",
      "description": "",
      "slug": "6th_grade",
      "thumbnail": "",
      "order": 6,
      "active": true,
      "category": "5ae9ac9a10e927a617919f9d"
    },
    {
      "_id": "62f515fbd62293f5fddcac9e",
      "title": "7th grade",
      "description": "",
      "slug": "7th_grade",
      "thumbnail": "",
      "order": 7,
      "active": true,
      "category": "5ae9ac9a10e927a617919fa0"
    },
    {
      "_id": "62f515fbd62293f5fddcac9f",
      "title": "8th grade",
      "description": "",
      "slug": "8th_grade",
      "thumbnail": "",
      "order": 8,
      "active": true,
      "category": "5ba1ed95d07dc55b348f37cd"
    },
    {
      "_id": "62f515fbd62293f5fddcac6f",
      "title": "9th grade",
      "description": "",
      "slug": "9th_grade",
      "thumbnail": "",
      "order": 9,
      "active": false,
      "category": ""
    },
    {
      "_id": "62f515fbd62293f5fddcac5f",
      "title": "10th grade",
      "description": "",
      "slug": "10th_grade",
      "thumbnail": "",
      "order": 10,
      "active": false,
      "category": ""
    },
    {
      "_id": "62f515fbd62293f5fddcac4f",
      "title": "11th grade",
      "description": "",
      "slug": "11th_grade",
      "thumbnail": "",
      "order": 11,
      "active": false,
      "category": ""
    },
    {
      "_id": "62f515fbd62293f5fddcac3f",
      "title": "12th grade",
      "description": "",
      "slug": "12th_grade",
      "thumbnail": "",
      "order": 12,
      "active": false,
      "category": ""
    }
  ]
};

@State<GradesModel>({
  name: 'APO_GRADES',
  defaults: _GradesDefault,
})
@Injectable()
export class GradesState implements NgxsOnInit, NgxsOnChanges {

  constructor() {}

  ngxsOnInit(ctx?: StateContext<any>): any {}
  ngxsOnChanges(change: NgxsSimpleChange<GradesModel>): void {}

  @Selector()
  static selectGrades( state: GradesModel ) {
    return state.list;
  }

  static selectGradeById( _id: number ) {
    return createSelector([ GradesState.selectGrades ], ( _grades: any) => {
      return _grades.find(x => x._id === _id);
    });
  }

}
