import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BhLoginComponent} from "./bh-login.component";
import {AppTranslocoRootModule} from "../../../app.transloco.module";
import {BhTooltipModule} from "../bh-tooltip/bh-tooltip.module";
import {BhDialogModule} from "../bh-dialog/bh-dialog.module";
import {RouterLink} from "@angular/router";



@NgModule({
  declarations: [BhLoginComponent],
  exports: [BhLoginComponent],
    imports: [
        CommonModule,
        AppTranslocoRootModule,
        BhTooltipModule,
        BhDialogModule,
        RouterLink
    ]
})
export class BhLoginModule { }
